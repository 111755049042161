@import "/src/styles/breakpoints.scss";
@import "/src/styles/variables.scss";

.controls {
  display: flex;
  position: absolute;
  top: 0;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  z-index: 999;
  width: 100%;
}

.indicator {
  position: absolute;
  display: flex;
  align-items: center;
  width: 70px;
  height: 100%;
  @include medium {
    width: 120px;
    // width: 180px;
  }
}

.previousIndicator {
  left: 0;
  justify-content: flex-start;
  background: linear-gradient(
    to left,
    rgba($color-neutral-1, 0),
    rgba($color-neutral-1, 1)
  );
}

.nextIndicator {
  right: 0;
  justify-content: flex-end;
  background: linear-gradient(
    to right,
    rgba($color-neutral-1, 0),
    rgba($color-neutral-1, 1)
  );
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid $color-secondary;
  height: 30px;
  width: 30px;
  cursor: pointer;
  border-radius: 22px;
  background: $color-neutral-1;
  @include medium {
    height: 44px;
    width: 44px;
  }
  svg {
    color: $color-secondary;
    height: 10px;
    transform-origin: center;
  }
}

.previousButton {
  margin-left: 10px;
  @include medium {
    margin-left: 10px;
  }
  svg {
    transform: rotate(90deg);
    transform-origin: center;
    // position: relative;
    // left: -2px;
  }
}

.nextButton {
  margin-right: 10px;
  @include medium {
    margin-right: 10px;
  }
  svg {
    transform: rotate(-90deg);
  }
}
