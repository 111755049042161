$tiny: 380px;
$small: 576px;
$medium: 768px;

@mixin tiny {
  @media (min-width: $tiny) {
    @content;
  }
}

@mixin small {
  @media (min-width: $small) {
    @content;
  }
}

@mixin medium {
  @media (min-width: $medium) {
    @content;
  }
}
