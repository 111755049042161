/* Color */
$color-black: #000000;
$color-white: #ffffff;

$color-yellow: #e6a822;
$color-red: #bf000a;

$color-neutral-1: rgb(235, 229, 221);
$color-neutral-2: #b3a48f;

// $scaffold-color: $color-neutral-1;
$scaffold-color: $color-black;
$surface-color: $color-neutral-1;

$color-primary: $color-yellow;
$color-secondary: $color-red;

$text-color: $color-black;

$selection-background: $color-secondary;
$selection-color: $color-primary;

/* Spacing */
$spacing-0: 0;
$spacing-1: 4px;
$spacing-2: 8px;
$spacing-3: 12px;
$spacing-4: 16px;
$spacing-5: 20px;
$spacing-6: 24px;
$spacing-8: 32px;
$spacing-10: 40px;
$spacing-12: 48px;
$spacing-16: 64px;

$side-padding: 10px;

$content-max-width: 820px;
$body-text-max-width: 530px;

/* Font Family */
$font-family-gibson: var(--font-gibson);
$font-family-flood-std: var(--font-flood-std);

/* Font Weight */
$gibson-regular: 400;
$gibson-semi-bold: 600;
$gibson-black: 900;

$font-weight-gibson-regular: $gibson-regular;
$font-weight-gibson-medium: $gibson-regular;
$font-weight-gibson-semi-bold: $gibson-semi-bold;
$font-weight-gibson-bold: $gibson-black;
$font-weight-gibson-heavy: $gibson-black;

$flood-std-regular: 400;

$font-weight-flood-std-regular: $flood-std-regular;

/* Border Radius */
$radius-sm: 2px;
$radius-md: 4px;
$radius-lg: 8px;
$radius-xl: 16px;
$radius-full: 9999px;

/* Z-index */
$z-index-dropdown: 1000;
$z-index-sticky: 1020;
$z-index-fixed: 1030;
$z-index-modal-backdrop: 1040;
$z-index-modal: 1050;
$z-index-popover: 1060;
$z-index-tooltip: 1070;
