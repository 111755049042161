@import "/src/styles/breakpoints.scss";
@import "/src/styles/variables.scss";

.section {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background: $color-black;
}

.overlay {
  position: absolute;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 400;
  @include medium {
    gap: 30px;
    background-color: rgba(0, 0, 0, 0.65);
  }
}

.mask {
  position: absolute;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  z-index: 500;
  background: url("/svg/curve-mask.svg") no-repeat center bottom;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: $content-max-width;
  padding: 0 $spacing-3;
  z-index: 600;
  p {
    color: $color-white;
  }
}

$ball-radius: 4px;
$ball-diamater: $ball-radius * 2;
$line-height: 40px;
$line-width: 2px;

/* Scroll button styles */
.scrollButton {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 500;
  bottom: 0;
  padding: 0 16px;
  cursor: pointer;
}

.scrollButtonText {
  color: #fff;
  line-height: normal;
  background: transparent;
}

.scrollButtonLine {
  height: $line-height;
  width: $line-width;
  background: #fff;
  position: relative;
}

.scrollButtonBall {
  position: absolute;
  top: 0;
  left: -($ball-radius - $line-width / 2);
  width: $ball-diamater;
  height: $ball-diamater;
  background-color: #fff;
  border-radius: 50%;
}

.scrollButtonBallAnimate {
  animation: moveBall 3.5s ease-in-out infinite;
}

@keyframes moveBall {
  0% {
    top: 0;
  }
  33% {
    top: $line-height - $ball-radius;
  }
  50% {
    opacity: 0;
  }
  // last 100% is the "delay" effect
  100% {
    opacity: 0;
    top: $line-height - $ball-radius;
  }
}

.imageTransitionsContainer {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.image {
  position: absolute;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 3;
  transition: opacity 0.5s linear, transform 3.5s linear;
  opacity: 0;
}

.active {
  opacity: 1;
  transform: scale(1);
}

.zoom {
  transform: scale(1.05);
}
